/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // Configuration
        'debug': true,
        // All pages
        'common': {
            init: function () {
                this.masonryBootstrap();
                this.owlCarouselGalleryBootstrap();
            },
            masonryBootstrap: function () {
                var $grid = $('.masonry-grid');

                if ($grid.length === 1) {
                    var $masonryGrid = $grid.isotope({
                        itemSelector: '.masonry-item',
                        percentPosition: true,
                        masonry: {
                            columnWidth: '.masonry-item'
                        }
                    });

                    $masonryGrid.imagesLoaded().progress(function () {
                        $masonryGrid.isotope('layout');
                    });

                    $('.filter-button-categories').on('click', 'button', function () {
                        $(this).closest('ul').find('li').each(function () {
                            $(this).removeClass('active');
                        });
                        $(this).closest('li').addClass('active');
                        var filterValue = $(this).attr('data-filter');
                        $masonryGrid.isotope({filter: filterValue});
                    });
                }
            },
            owlCarouselGalleryBootstrap: function () {
                var owlCarouselGallerySelector = '.owl-gallery-items-carousel';
                if ($(owlCarouselGallerySelector).length < 1) {
                    return;
                }
                var owlCarouselHeader = $(owlCarouselGallerySelector).owlCarousel({
                    loop: true,
                    nav: true,
                    dots: true,
                    autoplay: true,
                    items: 1
                });
            }
        },
        // Home page
        'home': {
            init: function () {
                this.owlCarouselBootstrap();
            },
            owlCarouselBootstrap: function () {
                var owlCarouselHeaderSelector = '.owl-home-items-carousel';
                if ($(owlCarouselHeaderSelector).length < 1) {
                    return;
                }
                var owlCarouselHeader = $(owlCarouselHeaderSelector).owlCarousel({
                    loop: true,
                    nav: true,
                    dots: false,
                    autoplay: true,
                    autoplayTimeout: 7000,
                    autoplayHoverPause: true,
                    items: 1
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
